import React from "react"
import Layout from "../../components/storyLayout"
import SEO from "../../components/seo"

const StoryPost = () => (
  <Layout storySrc="https://www.youtube.com/embed/S_TMJYIUKAQ">
    <SEO title="Prison Ministry - Story" />
  </Layout>
)

export default StoryPost
